import React from "react"
import { Link } from "gatsby"

export default class Footer extends React.Component {

render() {
  return (
    <footer className="bg-primary py-5" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 text-left">
            <div className="text-muted" >COPYRIGHT &copy; 2023</div>
            <div>
              <Link to="/privacy/" className="text-muted">Privacy Policy</Link>
            </div>
            <div className="social-icons text-left">
              <a href="https://www.facebook.com/groups/274423653503102/">
                <i className="fab fa-facebook fa-2x text-muted"></i>
              </a>
              <a href="https://instagram.com/wunderkuffler_real_estate?igshid=p1goxsrk6i5q">
                <i className="fab fa-instagram fa-2x text-muted"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCNKWOvgoEHmJpnJrJ5267CA">
                <i className="fab fa-youtube fa-2x text-muted"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-left border-left">
            <div className="text-muted">
              CONTACT
              <p className="text-muted">
                Wilson Underkuffler
                <br />
                <a className="text-muted" href="tel:2074415960">207 441 5960</a>
                <br />
                <a className="text-muted" href="mailto:will.underkuffler@gmail.com">will.underkuffler@gmail.com</a>
              </p>
            </div>
          </div>
          <div className="col-lg-4 text-left border-left">
          </div>
        </div>
      </div>
    </footer>
    )
  }
}
